// Node modules
import PropTypes from 'prop-types';

// Components
import Dot from './Dot';

const propTypes = {
  setSlide: PropTypes.func.isRequired,
  activeSlide: PropTypes.number.isRequired,
  slides: PropTypes.array.isRequired,
  viewPorts: PropTypes.shape({
    desktop: PropTypes.bool,
    isMobile: PropTypes.bool,
    tablet: PropTypes.bool,
  }).isRequired,
};

const defaultProps = {
  activeSlide: 0,
  slides: [],
  viewPorts: {
    desktop: false,
    isMobile: true,
    tablet: false,
  },
};

const Dots = ({
  viewPorts,
  slides,
  activeSlide,
  setSlide, 
}) => (
  <>
    <div>
      {slides.map((_, i) => (
        <Dot
          viewPorts={viewPorts}
          key={i}
          active={activeSlide === i}
          count={slides.length}
          slide={i}
          setSlide={setSlide}
        />
      ))}
    </div>
    <style jsx>{`
      div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    `}</style>
  </>
);

Dots.propTypes = propTypes;
Dots.defaultProps = defaultProps;

export default Dots;
