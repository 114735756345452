// Node modules
import PropTypes from 'prop-types';
import { memo } from 'react';

//themes
import theme from '@/themes/index';

const propTypes = {
  slide: PropTypes.number.isRequired,
  setSlide: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  viewPorts: PropTypes.shape({
    desktop: PropTypes.bool,
    isMobile: PropTypes.bool,
    tablet: PropTypes.bool,
  }).isRequired,
};

const defaultProps = {
  slide: 0,
  count: 0,
  active: false,
  viewPorts: {
    desktop: false,
    isMobile: true,
    tablet: false,
  },
};

const Dot = ({
  active,
  count,
  slide,
  setSlide, 
}) => (
  <>
    <a onClick={() => setSlide(slide)} />
    <style jsx>{`
      a {
        width: 1em;
        height: 1em;
        margin-right: ${count > 3 ? '0.5em' : '1.5em'};
        cursor: pointer;
        border-radius: 50%;
        border: 0.125em solid #00e065;
        background: ${active ? '#00E065' : 'transparent'};
      }

      @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
        a {
          width: 1.5em;
          height: 1.5em;
        }
      }
    `}</style>
  </>
);

Dot.propTypes = propTypes;
Dot.defaultProps = defaultProps;

export default memo(Dot);
