import { nanoid } from 'nanoid';

// Components
import Carousel from '@/components/global-components/carousel/Carousel';
import Review from './sub-components/Review';
import { carousel } from '@/scripts/constants';
import Container from '@/components/global-components/Container';

//themes
import theme from '@/themes/index';

export const Reviews = ({
  content: {
    reviewItems,
    title,
    subTitle,
  },
  showHeader = true,
  showButtons = true,
  isMobile,
}) => (
  <>
    <div className='container'>
      <div className='header-container'>
        {showHeader && 
        <>
          <h3>{title}</h3>
          <p>{subTitle}</p>
        </>}
      </div>
      <div className='reviews-container'>
        <Carousel
          showButtons={isMobile ? false :  showButtons}
          showPaging={true}
          transitionMode={carousel.FADE}
        >
          <Container>
            {reviewItems.map(({
              reviewItem: review, 
            }) => (
              <Review
                key={nanoid()}
                imageUrl={review.image?.url}
                author={review.author}
                location={review.location}
                reviewText={review.reviewText}
                title={review.title}
                position={review.position}
              />
            ))}
          </Container>
        </Carousel>
      </div>
    </div>
    <style jsx>{`
      h3 {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        line-height: 2.375rem;
      }
      div.container {
        width: 100%;
        text-align: center;
      }

      .header-container {
        flex-direction: row;
        padding-bottom: 1rem;
      }

      div.container .header-container p {
        text-align: center;
      }

      .programs-container {
        flex-direction: row;
        padding: 2.4rem 0 0;
      }

      @media only screen and (min-width: ${theme.breakpoints.tabletMin}) {
        h3 {
          line-height: 5.375rem;
        }

        .header-container {
          padding-bottom: 4.25rem;
        }
      }
    `}</style>
    <style global jsx>{`
      div.reviews-container .slider-control-centerright,
      div.reviews-container .slider-control-centerleft {
        top: 20% !important;
      }

      div.reviews-container .slider-control-bottomcenter {
        bottom: -10% !important;
        position: absolute;
      }

      @media only screen and (min-width: ${theme.breakpoints.tabletMin}) {
        div.reviews-container .slider-control-bottomcenter {
          bottom: -10% !important;
        }
      }
    `}</style>
  </>
);

export default Reviews;
