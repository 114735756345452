// Node Modules
import { RichText } from 'prismic-reactjs';

// Themes
import theme from '@/themes/index';

const Review = ({
  imageUrl,
  author,
  location,
  title,
  position,
  reviewText, 
}) => (
  <>
    <div className='container'>
      {imageUrl &&
        <div>
          <img src={imageUrl}></img>
        </div>
      }
      <div className='review'>
        <RichText render={reviewText} />
      </div>
      <h6 className='review-item'>{author}</h6>
      <p className='review-item position'>{position}</p>
      <p className='review-item title'>{title}</p>
      <p className='review-item location'>{location}</p>
      
    </div>
    <style jsx>{`
      div.container {
        display: flex;
        flex-direction: column;
        text-align: center;
      }

      p,
      h3,
      h6 {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
      }

      h3.title {
        font-family: ${theme.fontFamilies.montserratMedium};
      }

      p.subtitle {
        font-family: ${theme.fontFamilies.montserrat};
        padding-bottom: 3.125rem;
      }

      img {
        border-radius: 50%;
        height: 16rem;
        width: 15.9rem;
        object-fit: cover;
        object-position: 54% 9%;
        opacity: 1;
      }

      h6 {
        font-family: ${theme.fontFamilies.openSansBold};
      }

      p.location {
        font-family: ${theme.fontFamilies.openSans};
        padding: 0.5rem 0;
        letter-spacing: 0;
        color: ${theme.fontColors.optional3};
        text-align: center;
      }

      @media only screen and (min-width: ${theme.breakpoints.tabletMin}) {
        div.container img {
          width: 9.907rem;
          height: 10rem;
        }
      }
    `}</style>
    <style global jsx>{`
      .review p {
        font-family: ${theme.fontFamilies.openSans};
        color: ${theme.fontColors.secondary};
        line-height: 1.75rem;
        text-align: center;
      }

      .review-item {
        padding: 0.2rem 0;
      }
    `}</style>
  </>
);

export default Review;
