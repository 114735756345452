// Node modules
import { Children } from 'react';
import { default as NukaCarousel } from 'nuka-carousel';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from 'styled-jsx/css';

// Components
import Dots from './dots/Dots';

const getSlideButtonStyle = () => css.resolve`
  a {
    border-radius: 50%;
    cursor: pointer;
    align-items: center;
    width: 2.5em;
    height: 2.5em;

    border: 2px solid #e5e5e5;
    opacity: 1;
    padding: 0.8em;
    background-color: transparent;
    display: flex;
    align-items: center;
  }
`;

const propTypes = {
  autoplay: PropTypes.bool,
  autoplayInterval: PropTypes.number,
  children: PropTypes.array.isRequired,
  initialSlideHeight: PropTypes.number,
  initialSlideWidth: PropTypes.number,
  wrapAround: PropTypes.bool,
  setCurrentSlide: PropTypes.func,
  showButtons: PropTypes.bool,
  showPaging: PropTypes.bool,
  slideIndex: PropTypes.number,
  slidesToShow: PropTypes.number,
  cellSpacing: PropTypes.number,
  containerClassName: PropTypes.string,
  viewPorts: PropTypes.shape({
    desktop: PropTypes.bool,
    isMobile: PropTypes.bool,
    tablet: PropTypes.bool,
  }),
  transitionMode: PropTypes.string,
};

const defaultProps = {
  showButtons: false,
  showPaging: false,
  autoplay: false,
  initialSlideHeight: 0,
  initialSlideWidth: 0,
  wrapAround: false,
  autoplayInterval: 1000,
  slideIndex: 0,
  slidesToShow: 1,
  cellSpacing: 0,
  transitionMode: 'scroll',
};

const Carousel = ({
  children,
  slideIndex,
  autoplay,
  autoplayInterval,
  initialSlideHeight,
  initialSlideWidth,
  wrapAround,
  setCurrentSlide,
  showButtons,
  showPaging,
  viewPorts,
  slidesToShow,
  cellSpacing,
  containerClassName,
  transitionMode,
}) => {
  const buttonStyle = getSlideButtonStyle();

  return (
    <>
      <NukaCarousel
        framePadding={'0 0 120px 0'}
        containerClassName={containerClassName}
        slidesToShow={slidesToShow}
        transitionMode={transitionMode}
        initialSlideHeight={initialSlideHeight}
        initialSlideWidth={initialSlideWidth}
        renderCenterLeftControls={({
          previousSlide, 
        }) =>
          showButtons && (
            <a onClick={previousSlide} className={buttonStyle.className}>
              <FontAwesomeIcon icon={'angle-left'} />
            </a>
          )
        }
        renderCenterRightControls={({
          nextSlide, 
        }) =>
          showButtons && (
            <a onClick={nextSlide} className={buttonStyle.className}>
              <FontAwesomeIcon icon={'angle-right'} />
            </a>
          )
        }
        slideIndex={slideIndex}
        autoplay={autoplay}
        autoplayInterval={autoplayInterval}
        wrapAround={wrapAround}
        cellSpacing={cellSpacing}
        renderBottomCenterControls={({
          currentSlide,
          goToSlide, 
        }) => {
          setCurrentSlide && setCurrentSlide(currentSlide);

          const slides = Children.toArray(children);

          return (
            showPaging && (
              <Dots
                viewPorts={viewPorts}
                slides={slides}
                activeSlide={currentSlide}
                setSlide={slide => goToSlide(slide)}
              />
            )
          );
        }}
        defaultControlsConfig={{
          containerClassName,
        }}
      >
        {children}
      </NukaCarousel>
      {buttonStyle.styles}
      <style global jsx>{`
        .svg-inline--fa.fa-w-8 {
          width: 2.5em;
          height: 2.5em;
          color: #e5e5e5;
        }
        .dots-container{
          bottom: 40px !important;
        }

        .dots-container > div{
          margin-bottom: 0;
        }
      `}</style>
    </>
  );
};

Carousel.propTypes = propTypes;
Carousel.defaultProps = defaultProps;

const mapStateToProps = ({
  viewPorts, 
}) => ({
  viewPorts, 
});

export default connect(mapStateToProps, null)(Carousel);
