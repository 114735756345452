export const carousel = {
  FADE: 'fade',
  SCROLL: 'scroll',
  SCROLL_3D: 'scroll3d',
};

export const pageContentEntryIds = {
  home: 'home_page',
  whyCostaRica: '6tZApBg9L2jtwCycnm9ePR',
  spanishOnSite: '4IixLXedJ744DEmJv42bvx',
  spanishOnline: '2iKdT4CiNUpsNTMFjy5dXn',
  blog: '7MyVK1AC8bujTqNr17nBaM',
  contact: '1ed2wafAPL8utRBqDRANhC',
  locations: '4P6kpSEkFBKWoTDhrpnIW2',
  alliances: '35CtqBdtWDBDyt7z6n1Rph',
  aboutCPI: '6zBrv1DHEkXgWCCaoe9gl2',
  faq: '5GVodHt8qaQdRILaSlzQdW',
  accommodation: '5oaBcv24hFBTYp0M7iC5db',
};
